import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
import Onboarding from '../pages/Onboarding';
import VerifyEmail from '../pages/VerifyEmail';
import CompleteInvitation from '../pages/CompleteInvitation';
import LayouthAuth from '../layouts/LayoutAuth';
import LandingPage from '../pages/LandingPage';

const routes = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage/>,
  },
  {
    path: "/login",
    element: <div>Hello!</div>,
  },
  {
    path: "/onboarding",
    element: <LayouthAuth/> ,
    children: [
      {
        path: "",
        element: <Onboarding />
      },
      {
        path: "/onboarding/verify",
        element: <VerifyEmail/> ,
      },
      {
        path: "/onboarding/complete",
        element: <CompleteInvitation/> ,
      },
    ]
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <div>Hello!</div>
      </ProtectedRoute>
    ),
  },
  {
    path: "*",
    element: <div>Hello!</div>,
  },
]);

const MeniQRRouter: React.FC = () => (
  <RouterProvider router={routes} />
);

export default MeniQRRouter;