import {
  CSSProperties,
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributes
} from 'react';

import * as IconAssets from './assets';

const DEFAULT_ICON_SIZE_IN_PIXELS = 20;

export type iconSizes = 16 | 20 | 24 | 32 | 40 | 48;
export type IconType = keyof typeof IconAssets;
export type IconProps = DetailedHTMLProps<
  HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
> & {
  icon?: IconType | null;
  scaleTo?: iconSizes;
  viewBox?: string;
  loading?: boolean;
  fill?: string;
};

export function isIconType(icon: any): icon is IconType {
  return !!Object.keys(IconAssets).find((el) => el === icon);
}

const IconComponent = (
  { icon, viewBox, className, scaleTo, loading, fill, ...props }: IconProps,
  ref: React.ForwardedRef<HTMLSpanElement>
) => {
  if (!icon) {
    return null;
  }

  if (IconAssets[icon] === undefined) {
    console.error(`No Icon ( ${icon} ) present in Assets folder`);
    return <>NO ICON</>;
  }

  const Icon = IconAssets[icon];

  const scaleIcon: CSSProperties | undefined = scaleTo
    ? { transform: `scale(calc(${scaleTo}/${DEFAULT_ICON_SIZE_IN_PIXELS}))` }
    : undefined;

  // Create an object that conditionally includes the fill prop
  const iconProps = {
    ...(viewBox ? { viewBox } : {}),
    ...(fill ? { fill } : {})
  };

  return (
    <span
      ref={ref}
      style={scaleIcon}
      // className={cn(
      //   `inline-flex leading-none`,
      //   {
      //     'w-4': scaleTo === 16,
      //     'w-5': scaleTo === 20,
      //     'w-6': scaleTo === 24,
      //     'w-8': scaleTo === 32,
      //     'w-10': scaleTo === 40,
      //     'w-12': scaleTo === 48
      //   },
      //   className
      // )}
      className='inline-flex leading-none'>
      {loading ? null : <Icon {...iconProps} />}
    </span>
  );
};

export const Icon = forwardRef(IconComponent);
