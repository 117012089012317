import React from "react";
import { useTranslation } from 'react-i18next';
import { Icon } from "../components/Icon";

const Onboarding: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-row w-full h-screen justify-center items-center gap-5">
            Test onboarding ovo vamo je ikonica ----- <Icon icon="Info" scaleTo={48}/>

        </div>
    );
}

export default Onboarding;