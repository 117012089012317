import React from "react";
import { useTranslation } from 'react-i18next';
import { Icon } from '../components/Icon';

const LandingPage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-row w-full h-screen justify-center items-center gap-5">
            Landing pages
        </div>
    );
}

export default LandingPage;