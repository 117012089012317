import React from "react";
import { useTranslation } from 'react-i18next';

const CompleteInvitation: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="w-full h-screen flex flex-col justify-center items-center">
            Test Complete Invitation
        </div>
    );
}

export default CompleteInvitation;