import React from "react";
import { useTranslation } from 'react-i18next';
import { Outlet } from "react-router-dom";

const LayouthAuth: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="w-full h-screen flex flex-row gap-3 justify-center items-center">
            <div>odje treba slidza</div>
            <div><Outlet/></div>
        </div>
    );
}

export default LayouthAuth;