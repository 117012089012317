import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M13.3215 6.1071C12.879 6.27943 11.9123 6.98412 10.7952 7.94878C10.274 8.39881 9.85596 8.77493 8.03734 10.4305C5.6878 12.5692 4.93781 13.1539 3.99707 13.5802C3.7179 13.7067 3.63584 13.7245 3.40202 13.7095C3.18562 13.6957 3.07771 13.6618 2.86808 13.5419C2.72359 13.4593 2.58879 13.3917 2.56856 13.3917C2.49092 13.3917 2.76308 14.2335 2.92755 14.5022C3.62773 15.6458 4.84855 15.7611 6.25919 14.8166C7.18133 14.1992 7.86303 13.5505 10.5491 10.7335C11.588 9.64398 12.5497 8.72954 12.6567 8.72954C12.8438 8.72954 12.6681 9.32106 11.783 11.6698C10.9365 13.9161 10.8397 14.2607 10.9633 14.587C11.0437 14.7993 11.187 14.9285 11.4513 15.0271C11.7811 15.15 12.0748 15.135 12.3481 14.9814C12.7753 14.7414 13.612 14.1362 14.8217 13.1923C15.4863 12.6736 16.071 12.2303 16.1208 12.207C16.2489 12.1475 16.4066 12.1547 16.4535 12.2223C16.5149 12.3108 16.4725 12.7301 16.3234 13.5083C16.1635 14.3431 16.1451 14.6327 16.2353 14.8891C16.3211 15.1331 16.4784 15.2691 16.8209 15.3957C17.0614 15.4845 17.1655 15.4979 17.6163 15.4979C18.1117 15.4979 18.1509 15.491 18.4948 15.3443C18.8232 15.2041 19.1267 14.9636 19.1052 14.8607C19.1004 14.8379 19.0173 14.7879 18.9206 14.7496C18.5903 14.6188 18.4091 14.4936 18.3852 14.3798C18.3726 14.32 18.4219 13.6729 18.4945 12.9419C18.6364 11.5149 18.658 11.0048 18.5903 10.6818C18.5049 10.2745 18.1079 10.0409 17.4983 10.0391C16.9318 10.0375 16.7868 10.1299 14.9974 11.6324C13.6303 12.7805 13.3327 13.0158 13.0365 13.1832C12.5202 13.4749 12.5882 13.25 13.5095 11.6192C14.9632 9.04571 15.4464 8.05577 15.4888 7.56342C15.5455 6.90666 15.1516 6.38167 14.4108 6.12681C14.0624 6.00698 13.6 5.9986 13.3215 6.1071Z" fill="currentColor"
    />
  </svg>
);
export default SvgLogo;
