import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import AuthProvider from "./providers/AuthProvider";
import MeniQRRouter from "./router/MeniQRRouter";

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <AuthProvider>
        <MeniQRRouter />
      </AuthProvider>
    </I18nextProvider>
  );
}

export default App;