import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10 9.167v4.166m0 4.167a7.5 7.5 0 1 1 0-15 7.5 7.5 0 0 1 0 15m.041-10.833v.083H9.96v-.083z"
    />
  </svg>
);
export default SvgInfo;
